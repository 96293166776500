body {
  margin: 0;

  overflow-x: hidden;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --defaultBtn-bg: #5B5B5B;
  --defaultBtn-border: #9B9B9B;
}

#root {
  height: 100vh !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.pointer {
  cursor: pointer;
}

/* ///////// validation ////////// */

.invalid-feedback {
  display: unset !important;
  position: relative;
}

/* ///////// validation ////////// */

/* ////////// header //////////// */
nav {
  padding: 20px;
  background-color: white;
  border: 1px solid #c3c3c3;
  border-right: 0;
  border-left: 0;
}

.nav-link {
  color: white !important;
  font-weight: 400;
}

button.bg-none.btn.btn-secondary {
  /* background: transparent !important; */
  border: 0;
}

table.table.tabNew {
  width: max-content;
  min-width: 100%;
}

.layout-container {
  /* float: left; */
  /* min-height: calc(100vh - 150px) !important; */
  /* min-width: 97vw !important; */
  /* margin: 10px 20px; */
}

._mainContainer {
  height: calc(100vh - 58px);
}

.tabMain_head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #9B9B9B;
  border-top: 0;
  padding: 11px;
}

.addPlan {
  border: 0;
  background: #665307;
  color: #fff;
  padding: 10px 2rem;
  /* width: 170px; */
  border-radius: 5px;
}

.tabBtn_bx {
  display: flex;
  gap: 1em;
}

.tabBtn_bx button {
  background: #fff;
  border: 0;
  font-size: 15px;
  font-weight: 400;
  position: relative;
  
}

.tabBtn_bx button.active {
  font-weight: 700;
}

.navbar-toggler:focus {
  box-shadow: 0 0 0 0 !important;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: transparent;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.2em;
  height: 1.2em;
}

.toast_container {
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 100;
}

@media (min-width: 768px) {
  .navbar-expand-md .navbar-collapse {
    border: 0 !important;
  }

  .container-fluid {
    /* flex-direction: row-reverse; */
  }
}

@media (max-width: 512px) {
  div {
    font-size: 16px !important;
    line-height: 14px !important;
  }

  a {
    font-size: 16px !important;
    line-height: 14px !important;
  }

  p {
    font-size: 16px !important;
    line-height: 14px !important;
  }

  b {
    font-size: 16px !important;
    line-height: 14px !important;
  }

  button {
    font-size: 16px !important;
    line-height: 14px !important;
  }
}

.head_outDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

/* ////////// header //////////// */

/* ////////// login //////////// */
.login_div {
  width: 100%;
  height: 100%;
  background-color: #665307;
  display: grid;
  align-items: center;
}

.login_form {
  margin: 0 35px;
}

.login_form_head {
  color: #665307;
  border: none !important;
  margin-top: 35px;
  font-size: 2rem;
}

.login_title {
  max-width: 130px;
}

.login_form_head p {
  color: #868686;
}

.login_form_body {
  /* color:white; */
  /* border-radius: 0 0 25px 25px; */
  padding-top: 30px;
}

.chk_login {
  color: #868686;
}

input.login_inp.form-control {
  padding: 10px;
}

/* button */

.login_form_btn {
  background-color: #665307;
  color: #fff;
  border: none;
  border-radius: 5px;
  width: 100% !important;
  /* margin: 15px 0 0 0 ; */
  padding: 10px;
}

.login_form_btn:hover {
  background-color: #665307;
  /* color:#b7cbd5; */
  border: none;
  width: 100% !important;
  /* margin: 15px 0 0 0 ; */
}

.login_form_btn:active {
  background-color: #44447c !important;
  color: white;
  border: none;
  border-radius: 5px;
  width: 100% !important;
  /* margin: 15px 0 0 0 ; */
}

.login_form_btn.btn.btn-outline-secondary.disabled {
  background-color: #50492d;
  color: #fff;
  border: none;
  border-radius: 5px;
  width: 100% !important;
  /* margin: 15px 0 0 0 ; */
  padding: 10px;
}

.btn:first-child:active,
:not(.btn-check)+.btn:active {
  background-color: #665307 !important;
  color: white;
  border-color: #665307;

  /* background-color: #44447c !important;
  color: white;
  border-color: #44447c; */
}

.login_card {
  border: 0;
  max-width: 485px;
  /* height: 420px; */
  background: #ffffff;
  border-radius: 4px;
  margin: 0 auto;
}

/* ////////// login //////////// */

/* /////////Sidebar//////////// */
.ques_row {
  display: flex;
  justify-content: space-between;
  padding: 8px 30px;
  align-items: center;
  border-bottom: 1px solid #c3c3c3;
  min-height: 60px;
}

.ques_row_sections {
  display: flex;
  padding: 8px 30px;
  align-items: center;
  border-bottom: 1px solid #c3c3c3;
  min-height: 56px;
  gap: 2em;
}

.tabStyle_Steps {
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
}

.ques_row h5 {
  margin: 0;
  font-weight: 400;
  color: #202020;
}

.ques_row_btn {
  padding: 10px 30px;
  background-color: #21888b;
  border-color: #21888b;
  font-weight: 500;
}

.ques_row_btn:hover {
  background-color: #21888b;
  border-color: #21888b;
  color: white;
}

.ques_row_btn:active {
  background-color: #44447c !important;
  border-color: #44447c;
  color: white;
}

.table_head_style {
  font-size: 14px;
}

.table_head_style tr {
  background-color: #E6EEFC !important;
 
}

.ques_table_head {
  color: #000000;
  background-color: #E6EEFC !important;
}

.lable_input_ques {
  /* padding: 0 0 40px; */
  display: flex;
  flex-direction: column;
}

.lable_input {
  padding: 30px 30px 60px;
  display: flex;
  flex-direction: column;
}

.modal_input {
  margin-top: 10px;
  padding: 10px;
  font-size: 14px;
  border-radius: 4px !important;
}

.modal_label {
  font-weight: 500;
}

.modal_input_in {
  width: 25% !important;
  flex: 0 0 25% !important;
  padding: 10px;
  border: 1px solid hsl(0, 0%, 80%);
  border: 1px solid #b6b6b6;
  border-radius: 0px;
}

.modal_input_btn {
  padding: 10px;
  background-color: #44447c;
  border-color: #44447c;
  color: white;
  border: none;
  border-radius: 5px;
  max-width: 160px;
}

.paddContol-r {
  padding-right: 0;
}

.paddContol-l {
  padding-left: 0;
}

table.table.tabNew tr td:nth-child(1) {
  padding-left: 20px;
}

table.table.tabNew tr th:nth-child(1) {
  padding-left: 20px;
}

table.table.tabNew tr td {
  vertical-align: middle;
}

.all_input_ques {
  margin: 30px;
}

.custom_option {
  padding: 0 5px;
  font-size: large;
  cursor: pointer;
}

/* /////////Sidebar//////////// */

/* ////////QuestionBankModal/////////*/

.qb_outerDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.1em;
}

.qb_innerDiv {
  display: flex;
  align-items: center;
  gap: 1.2em;
  margin-bottom: 1rem;
}

.align {
  margin-bottom: 0 !important;
}

.anvOt_gridBx {
  display: flex;
  flex-wrap: wrap;
  gap: 0em;
  column-gap: 1em; /* Reduced column gap */
  margin-bottom: 0.5em;
  margin-right: 20px;
  margin-left: 15px;
}

.anvOt_gridBxDr{
  display: flex;
  flex-wrap: wrap;
  gap: 2em;
  margin-bottom: 0.5em;
}

.drFlex{
  display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
}

.anvOt_gridBx_modal {
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
  margin-bottom: 0.5em;
}
.anvOt_outerFull {
  width: 100%;
}
.anvOt_gridBx_modal {
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
  margin-bottom: 0.5em;
}
.customOffCanvs .offcanvas-body {
  padding-bottom: 0;
  padding-right: 1px;
  /* padding-left:  2rem; */

}

.anvMaintab_container {
  padding: 1em 18px 0;
  height: calc(100vh - 161px);
  overflow: auto;
}
.anvOt_outerFull {
  width: 100%;
}
.anvOt_inputField {
  width: calc(50% - 16px);
}


.anvAdd_drive .anvOt_inputField .mb-3 {
  margin-bottom: 0 !important;
}

.anvAdd_fuel .anvOt_inputField .mb-3 {
  margin-bottom: -3px !important;
}
.partMarg {
  margin: 2em 0 12px;
  font-size: 14px;
  font-weight: 700;
}

.anvAdd_drive .anvOt_inputField.checkboxtext {
  margin-bottom: 16px;
  align-items: end;
}

.anvAdd_drive .anvOt_inputField.checkboxtext sapn.checkboxTextbox {
  margin: 0;
}
.anvTxt_fielW.input-group input.is-invalid.form-control {
  z-index: 0;
}

.anvOt_inputFieldFull {
  flex: 0 0 100%;
}

.FormLableKaran {
  font-size: 14px;
}

.anvOt_dropBx {
  width: calc(50% - 8px);
}

.anvOt_btn {
  background-color: #665307 !important;
  margin: 0px 6px;
  border-color: #665307;
}

.anvOt_btn:hover {
  background-color: #665307;
  border-color: #665307;
  color: white;
}

.anvOt_btn_rmv {
  background-color: #e6eefc;
  border-color: #44447c;
  font-weight: 500;
  color: #665307;
  margin: 0px 6px;
  border: none;
}

.anvOt_btn_rmv:hover {
  background-color: #e6eefc;
  color: #665307;
  margin: 0px 6px;
  border: none;
}

.qb_innerDiv .style_switch {
  display: flex;
  padding: 0;
}

.style_switch {

  cursor: pointer;
}

.qb_innerDiv .style_switch span {
  display: block;
}

.qb_innerDiv .style_switch input.form-check-input {
  margin-left: 5px;
}

.modalOut_div {
  display: flex;
  align-items: center;
  gap: 1em;
  margin-bottom: 1.1em;
}

.modalOut_inpDiv {
  width: 10%;
}

.qb_custumBtn_x {
  cursor: pointer;
  color: #c3c3c3;
  font-size: larger;
  margin-bottom: 1.1rem;
}

.qb_custumBtn_div {
  display: flex;
  gap: 1em;
}

.qb_custumBtn {
  cursor: pointer;
  color: #214171;
}

.mb-3 {
  position: relative;
}

.mb-3 .marhin_fuel {
 margin-bottom: -3 !important;
}

.qb_innerDiv .style_switch input.form-check-input {
  background-image: url(./image/uncheck_dot.png) !important;
}

.qb_innerDiv .style_switch input.form-check-input:checked {
  background-image: url(./image/checkedDot.png) !important;
  background-color: #fff !important;
}

.qb_innerDiv .style_switch input.form-check-input:focus {
  border-color: #00000040 !important;
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 0%) !important;
}

.circInput_bx .form-check input.form-check-input {
  border: 1px solid #000000 !important;
}

.circInput_bx .form-check input.form-check-input:checked[type="radio"] {
  background-image: url(./image/Ellipse_black.png) !important;
  background-size: 10px;
  background-color: #fff;
}

.circInput_bx .form-check input.form-check-input:focus {
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 0%);
}

.form-check-input:focus {
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 0%);
}

.form-check-input:focus,
:active {
  border-color: rgba(0, 0, 0, 0.25);
  filter: unset;
}

.attach_radio {
  display: flex;
  gap: 1em;
}

/* ////////QuestionBankModal/////////*/

/* ////////QuestionarieSteps/////////*/

.steps_outerDiv {
  display: flex;
  align-items: flex-end;
  gap: 2em;
  /* margin-bottom: 1.5em; */
}

.steps_btn {
  background-color: #214171;
  color: white;
  padding: 8px 25px;
  border: none;
  border-radius: 4px;
  margin-bottom: 1rem;
}

/* ////////QuestionarieSteps/////////*/

.viewstep_div {
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: calc(100vh - 114px);
}

.style_pagination {
  display: flex;
  justify-content: end;
  border: none;
}

.style_pagination nav {
  border: none;
}

.viewstep_maindiv {
  display: flex;
  justify-content: space-between;
  border: 1px solid #e3e3e3;
  margin: 10px;
  padding: 10px;
  margin-bottom: 30px;
}

.viewstepsection_maindiv {
  display: flex;
  justify-content: space-between;
  border: 1px solid #e3e3e3;
  margin: 30px;
  padding: 10px;
  margin-bottom: 100px;
  width: 50%;
}

.pad_Div {
  padding: 5px 0;
  gap: 1em;
}

.stylemodalfooter {
  display: block;
  padding: 0;
}

.stylemodaltable th,
td {
  border-bottom: 3px solid #dbdbdb;
}

.stylemodalinp {
  width: 24%;
}

.stylemodaldropBx {
  width: 24%;
}

.anvTxt_fielW.input-group {
  /* max-width: 368px; */
  width: 100%;
  flex-wrap: nowrap;

}

.anvTxt_fielW.input-group input.modal_input_in.form-control {
  width: 100% !important;
  flex: 0 0 100% !important;
}

.anvTxt_fielW.input-group input.modal_input_in.newInp_ch.form-control {
  max-width: 368px;
}

.anvTxt_fielW.input-group input.modal_input_in.newInp_ch.form-control:not(:focus).is-invalid,
.anvTxt_fielW.input-group input.modal_input_in.newInp_ch.form-control:focus {
  z-index: 0;
}

.anvTxt_fielW.input-group span.input-group-text {
  position: relative;
  right: 44px;
  top: 1px;
  height: 100%;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  padding: 0;
}

.anvTxt_fielW.input-group span.input-group-text svg.pointer {
  width: 42px;
  height: 100%;
  padding: 14px;
}

.anvOt_dropBx.lable_input_ques.anNew_drop_contain {
  width: 100%;
  max-width: 368px;
}

.anvOt_dropBx.lable_input_ques.anNew_drop_contain .anvSel_drop .css-13cymwt-control,
.anvOt_dropBx.lable_input_ques.anNew_drop_contain .anvSel_drop .css-t3ipsp-control {
  min-height: 48px;
  border-radius: 0;
  border-width: 2px;
}

.content .bottomBtn_div {
  width: 100%;
  transition: 0.5s;
}

.content.is-open .bottomBtn_div {
  width: calc(100% - 250px);
  transition: 0.5s;
}

.bottomBtn_div {
  display: flex;
  padding: 0 1em;
  gap: 2em;
  align-items: center;
  border-top: 1px solid #c3c3c3;
  min-height: 56px;
  position: fixed;
  bottom: 0px;
  right: 0;
  z-index: 99;
  left: auto;

  background: #fff;
}

.createBtn {
  padding: 10px 30px;
  margin: 10px 0;
  background-color: #44447c;
  border-color: #44447c;
}

.createBtn:hover {
  background-color: #44447c;
  border-color: #44447c;
  color: white;
}

.question_input {
  border: 0;
  border-bottom: 2px solid #ced4da !important;
  border-radius: 0;
}

.question_input:focus {
  border: hidden !important;
  box-shadow: none;
  border-bottom: 2px solid #86b7fe !important;
  /* border-color: #86b7fe; */
  box-shadow: 0px 15px 10px -15px inset 1px 0px 0 0.25rem rgb(13 110 253 / 25%);
}

.point_input {
  width: 150px;
}

.stylemodaldropBx {
  width: 24%;
}

.stylespinner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80vh;
  width: 100%;
  background-color: rgb(251, 251, 251);
}

.nodatafound {
  text-align: center;
  font-size: large;
  font-weight: 600;
  color: #c3c3c3;
}

.form-control.is-invalid:focus,
.was-validated .form-control:invalid:focus {
  box-shadow: 0 0 0 0.25rem rgb(220 53 69 / 0%);
}

.question_input.is-invalid:focus {
  border-bottom: 2px solid #dc3545 !important;
}
.anvFleetType_modal .modal-content {
  border-color: #B3B3B3;
  border-radius: 0;
}

.anvFleetType_modal .footerOfModal {
  margin: 0;
}
.anvFleetType_modal .ModalFlexDiv {
  width: 100%;
  padding: 0;
}
.anvFleetType_modal .ModalFlexDiv .flex.labelwithinput {
  gap: 19px;
}
.anvFlt_inrFx {
  display: flex;
  flex-wrap: wrap;
  grid-column-gap: 38px;
  grid-row-gap: 19px;
}

.anvFleetType_modal .ModalFlexDiv .flex.labelwithinput .mb-3 {
  margin: 0 !important;
}

.anvBasis_flt {
  flex: 0 0 calc(50% - 19px);
}

.anvFleetType_modal .ModalFlexDiv .flex.labelwithinput .anvOt_dropBx {
  width: 100%;
}

.anvFleetType_modal .ModalFlexDiv .flex.labelwithinput input.modal_input.form-control {
  margin: 0;
  border-radius: 5px;
}

.input.modal_input.form-control{
  border-radius: 5px;

}

.anvLiq_select__control {
  min-height: 43px;
  border-color: #dee2e6;
}
@media only screen and (max-width: 767px) {
  .tabFix {
    overflow-y: auto;
  }

  .tabFix table.table.tabNew {
    width: 800px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .tabFix {
    overflow-y: auto;
  }

  .tabFix table.table.tabNew {
    width: 991px;
  }
}

.questionnaire_link {
  text-decoration: none;
  color: #214171;
  /* font-size: 16px; */
}

.questionnaire_link:hover {
  color: #214171;
  text-decoration: underline;
}

.page-link:focus {
  box-shadow: none !important;
}

.anvFilter_contain .anvDrop_dwn__value-container {
  padding: 7px 8px;
}

.anvFilter_contain {
  max-width: 300px;
}

button.anv_togleBtn {
  background-color: #665307;
  border-color: #665307;
}

button.anv_togleBtn:hover {
  background-color: #665307;
  border-color: #665307;
}

._anvPagintion_bx ul.pagination {
  margin: 0;
}

._anvQuest_modal {
  height: 90vh;
}

._anvQuest_modal .modal-content {
  height: 100%;
}

.anvMod_body {
  /* max-height: 500px; */
  overflow: auto;
}

.modal_otrDiv {
  display: flex;
  width: 100%;
}

.disabled_input {
  caret-color: transparent;
  cursor: not-allowed;
  border: 0;
  border-bottom: 2px solid #ced4da !important;
  border-radius: 0;
}

.disabled_input:focus {
  border-bottom: 2px solid #ced4da !important;
}

p.lgHead_para {
  max-width: 318px;
  margin: 0 auto;
}

.stepSection_contain {
  border: 1px solid #e3e3e3;
  margin: 0 30px;
  padding: 30px 20px;
}

.steps_outerDiv._grdFrm {
  display: grid;
  gap: 0em;
}

.steps_outerDiv._grdFrm .viewstepsection_maindiv {
  margin: 30px 0;
}

.qa_frm {
  display: flex;
  align-items: end;
  gap: 1em;
}

.steps_outerDiv._grdFrm button.steps_btn {
  height: 38px;
}

.anvTask_prog {
  display: flex;
  gap: 14px;
  margin-top: 1em;
  margin-left: 1em;
 
}

.margin-buttom{
  margin-bottom: 1rem;
}

.tskName_bx {
  flex: 0 0 254px;
}

.tskName_bx input.modal_input_in.newInp_ch.form-control {
  border-radius: 4px !important;
}

.tskName_bx .anvTxt_fielW.input-group span.input-group-text {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.dashboard_container {
  display: flex;
  gap: 2em;
  padding: 2em 2em;
  flex-direction: column;
  height: calc(100vh - 106px) !important; 
}

.dashboard_div1 {
  display: flex;
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 0.92em 2em;
}

.dash_subdiv:nth-child(2) {
  padding: 0 3.3em;
  margin: 0px 3.3em;
  position: relative;
  justify-content: center;
}

.dash_subdiv:nth-child(2):after {
  content: "";
  height: 41px;
  width: 1px;
  background-color: #ececec;
  position: absolute;
  right: 0;
}

.dash_subdiv:nth-child(2)::before {
  content: "";
  height: 41px;
  width: 1px;
  background-color: #ececec;
  position: absolute;
  left: 0;
}

.dash_subdiv {
  display: flex;
  flex-direction: column;
}

.dash_txt {
  color: #5a5a5f;
  font-size: 18px;
  font-weight: 500;
}

.dash_num {
  color: #21888b;
  font-size: 32px;
  font-weight: 500;
}

.custom-tooltip {
  padding: 5px 12px;
  background-color: #000000df;
  border-radius: 4px;
  color: #fff;
  position: absolute;
  top: -13px;
  right: 70px;
}

.share-icon {
  cursor: pointer;
  font-size: 16px;
  color: #44447c;
}

.action-tab {
  position: relative;
}

.stepSection_contain {
  border: 1px solid #e3e3e3;
  margin: 0 30px;
  padding: 30px 20px;
}

.steps_outerDiv._grdFrm {
  display: grid;
  gap: 0em;
}

.steps_outerDiv._grdFrm .viewstepsection_maindiv {
  margin: 30px 0;
}

.qa_frm {
  display: flex;
  align-items: end;
  gap: 1em;
}

.steps_outerDiv._grdFrm button.steps_btn {
  height: 38px;
}

.stepsbtn_div {
  display: flex;
  flex-direction: column;
  gap: 1em;
  margin-top: 15px;
}

.anvOt_btn_remove {
  background-color: #e6eefc;
  font-weight: 500;
  color: #3f86ff;
  padding: 10px 30px;
  border: none;
}

.anvOt_btn_remove:hover {
  background-color: #e6eefc;
  color: #3f86ff;
  border: none;
}

.anvOt_btn_remove:first-child:active {
  background-color: #e6eefc !important;
  color: #3f86ff !important;
  border: none;
}

.anvOt_btn_save {
  padding: 10px 30px;
  background-color: #214171;
}

.anvOt_btn_save:hover {
  background-color: #214171;
  color: white;
}

.anvAdd_ques .invalid-feedback {
  position: absolute;
}

.anvAdd_ques .otr_addDrop,
.anvAdd_ques .anvPoin_inp {
  /* margin-bottom: 2.5em !important; */
}

.anvAdd_ques .otr_addDrop .form-control {
  padding: 0.375rem 0.75rem !important;
}

.detailsName {
  cursor: pointer;
  color: blue;
}

.modalOut_div.anvAdd_ques {
  margin-bottom: 2.2em;
}

.action-tab {
  position: relative;
}

.stepSection_contain {
  border: 1px solid #e3e3e3;
  margin: 0 30px;
  padding: 30px 20px;
}

.steps_outerDiv._grdFrm {
  display: grid;
  gap: 0em;
}

.steps_outerDiv._grdFrm .viewstepsection_maindiv {
  margin: 10px 0;
}

.qa_frm {
  display: flex;
  align-items: end;
  gap: 1em;
}

.steps_outerDiv._grdFrm button.steps_btn {
  height: 38px;
}

.breadcrumb {
  margin-bottom: 0;
}

.breadcrumb_container {
  align-items: center;
  border-bottom: 1px solid #c3c3c3;
  padding: 8px 20px;
}

.breadcrumb_container>h5 {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  margin: 0;
}

.breadcrumb_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.breadcrumb_box>nav {
  border: 0 !important;
  background: transparent;
}

.breadcrumb_menu {
  display: flex;
  justify-content: space-between;
  padding: 0 !important;
  align-items: center;
  border-bottom: 1px solid #c3c3c3;
}

.breadcrumb_menu h5 {
  margin-bottom: 6px;
  font-weight: 600;
  color: #202020;
  font-size: 20px;
}

.breadcrumb-item+.breadcrumb-item::before {
  content: ">" !important;
}

.ques_row_sections.anvQuest_rowSec {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  gap: 0;
}

.ques_row_sections.anvQuest_rowSec .tabStyle_Steps {
  display: block;
  line-height: 56px;
  padding: 0 1.5em;
  position: relative;
}

span.toggleActive.tabStyle_Steps:after {
  content: "";
  width: 100%;
  height: 4px;
  background-color: #214171;
  position: absolute;
  bottom: 0;
  left: 0;
}

._anvPagintion_bx {
  margin-right: 1.3em;
  padding-bottom: 0.5em;
  position: absolute;
  bottom: 0;
  right: 0;
}

li.page-item.active a.page-link {
  background-color: #ad8c06;
}

ol.breadcrumb {
  display: block;
}

ol.breadcrumb a.breadcrumb-item {
  text-decoration: none;
  color: #202020;
  opacity: 0.6;
  font-weight: 600;
  font-size: 14px;
}

h6.active.breadcrumb-item {
  color: #21888b;
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  margin: 0;
}

.breadcrumb-item+.breadcrumb-item::before {
  float: none;
}

.reviewQuest_bx {
  margin-left: 62px !important;
}

._innrQuest_bx {
  margin-top: 20px !important;
}

.answer-text {
  color: #393939;
  padding-left: 10px;
}

._innrQuest_bx>h6 {
  color: #214171;
  font-size: 16px;
}

.section-title>h6 {
  font-size: 18px;
  color: #214171;
}

.qustLab.w-75>span {
  font-size: 15px;
}

.modal_heading {
  color: #214171;
  font-size: 20px;
}

.not_found_text {
  margin: 127px 280px;
}

.tabFix {
  min-height: calc(100vh - 245px);
      height: calc(100vh - 245px);
  overflow: auto;
  margin-bottom: 0px;
}

.tabFix thead.table_head_style {
  position: sticky;
  top: 0;
}

.anvStatic_hg {
  min-height: calc(100vh - 196px);
  height: calc(100vh - 196px);
  overflow: auto;
  margin: 0;
  padding: 30px;
}

.page-link:hover {
  color: #00515c;
}

.page-link {
  color: #214171 !important;
}

.active>.page-link,
.page-link.active {
  border-color: #21888b;
  color: #fff !important;

}

._starMain_detailBx {
  border: 1px solid #6AD6E5;
  background: #F4FEFF;
  border-radius: 3px;
  margin: 10px 15px;
  padding: 15px;
}

._str_tHead {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
  font-size: 16px;
}

._strFsr {
  flex: 0 0 170px;
}

._strSec {
  font-weight: 700;
}

.pointHead p {
  max-width: 47%;
  margin-left: auto;
}

.pad_Div .anvPo_list {
  max-width: 428px;
  width: 428px;
  display: flex;
  gap: 5px;
}

.span_for_questions {
  margin-left: 57px;
}

.style_for_star {
  color: red;
}

label span {
  color: red;
}

.add_que_modal {
  min-height: 250px;
}

/* ///////////////////////////////////////////////////////////////// */

.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
  background-color: #89898a !important;
  cursor: not-allowed;
}

.checkbox_head {
  font-weight: 600;
}

.userList_status_active {
  background-color: #44447c;
  color: white;
  padding: 2px 21px;
  border: none;
  border-radius: 4px;
  margin-top: 3px;
}

.userList_status_Inactive {
  background-color: rgb(237, 84, 84);
  color: white;
  padding: 2px 12px;
  border: none;
  border-radius: 4px;
  margin-top: 3px;
}

/* 
.statuBtn{
    padding: 6px 20px;
    background-color: #44447c;
    border-color: #44447c;
    font-weight: 500;
} */

.img_sizw img {
  width: 45%;
}

.tabFix thead.table_head_style th {
  background: transparent;
}


.flex {
  display: flex;
}


.anvInputs\&fillters {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  width: 100%;
}

.inr_flex {
  flex: 0 0 254px;
}

.inr_flex_mini {
  flex: 0 0 153px;
}

.inr_flex_filter {
  flex: 0 0 100px;
}

button.filterBox {
  width: 99px;
  height: 41px;
  border-radius: 4px;
  border: 1px solid #9B9B9B;
  background: #D9D9D9;
}


.anvRc_controller__input-container {
  padding-top: 3px;
  padding-bottom: 3px;
}

input.anvSearch_input {
  padding-top: 7px !important;
  padding-bottom: 7px !important;
  border: 1px solid #B6B6B6;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

.anvRc_controller__control {
  border-color: #B6B6B6 !important;
}

.actionBtn {
  display: flex;
  gap: 1em;
  /* align-items: center; */
}

.anvMain_ogGrid {
  padding: 25px 32px;
}

.ModalFlexDiv {
  width: 340px;
  padding: 0 0 1rem 1rem;
  flex-direction: column;
  display: flex;
  gap: 1rem
}

.modal-dialog.ModalWidth {
  width: 476px;
  border-radius: 4px;
}

.labelwithinput {
  flex-direction: column;
  gap: 5px
}

.addtagButton {
  background: #44447c
}

.addtagButton:hover {
  background: #44447c
}

.cancletagButton {
  background: #E6EEFC;
  color: #3F86FF
}

.cancletagButton:hover {
  background: #E6EEFC;
  color: #3F86FF
}

.ModalFlexDiv label {
  font-size: 14px;
  font-weight: 500;
}

.file_input {
  width: 154px;

}

.customOffCanvs {
  width: calc(100% - 50%) !important;
  display: flex;
}

.offcanvas-header {
  border-bottom: 1px solid #C3C3C3;
  height: 55px;
}

.offcanvesFooter {
  height: 55px;
  width: 100%;
  border-top: 1px solid #C3C3C3;
  display: flex;
  align-items: center;
  padding: 1rem 1.5rem !important;
}

.custom-file-input {
  color: transparent;
  border: none !important;
  padding-left: 0;

}

.custom-file-input:focus {
  color: transparent;
  border: none;
  padding-left: 0;
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 0%);
}

.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}

.custom-file-input::before {
  content: 'Select Picture';
  color: #3C3C3C;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #E8E8E8;
  border: 1px solid #B6B6B6;
  border-radius: 3px;
  padding: 5px 8px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  text-shadow: 1px 1px #fff;
  font-weight: 700;
  font-size: 10pt;
  width: 175px;
  height: 40px;
}

.custom-file-input:hover::before {
  border-color: black;
}

.custom-file-input:active {
  outline: 0;

}

.custom-file-input:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}


.custom-file-input:focus {
  color: transparent;
  border: none;
  padding-left: 0;
}

.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}

.custom-file-input::before {
  content: 'Select Picture';
  color: #3C3C3C;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #E8E8E8;
  border: 1px solid #B6B6B6;
  border-radius: 3px;
  padding: 5px 8px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  text-shadow: 1px 1px #fff;
  font-weight: 700;
  font-size: 10pt;
  width: 175px;
  height: 40px;
}

.custom-file-input:hover::before {
  border-color: black;
}

.custom-file-input:active {
  outline: 0;
}

.custom-file-input:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}


.widthOfgrid {
  gap: 0.5em !important;
}

.switchInput {
  cursor: pointer;


}

.anvOt_drp_high {
  gap: 40px;
}

.anvOt_inputField_hg {
  width: calc(70% - 20px);
}

.anvOt_dropBx_hg {
  width: calc(30% - 20px);
}

.anvOt_inputField_mini {
  flex: 0 0 355px;
}

.postAddon_inp {

  right: 0 !important;
  height: unset !important;
}

.anvOt_socialBx {
  gap: 30px;
}

.anvOt_socialInp {
  width: calc(33% - 20px);
}


.BodyOfCnves {
  padding: 3rem 0rem 0rem 2rem !important;
}

.imagePost {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.imageBoxSrc img {
  width: 177px !important;
  height: 122px;
  border: 1px solid #B6B6B6;
  border-radius: 4px;
}

.imageBoxSrc_witout {
  width: 177px !important;
  height: 122px;
  border-radius: 4px;
  border: 1px solid #B6B6B6;
  background: #E8E8E8;
  display: flex;
  justify-content: center;
  align-items: center;

}

.imageBoxSrc_witout span {
  font-size: 14px;
  font-weight: 500;
}


.eyeView {
  font-size: 25px;
  cursor: pointer;
}

.actionBtn img {
  cursor: pointer;
  width: 20px;
  margin-top: 3px;
  height: 20px;
  width: 20px;
  /* margin-top: 3px; */
  height: 20px;
}


.category_image img {
  width: 48px;
  height: 48px;
  border-radius: 50%;
}

.Image_croper_container {
  width: 100%;
  height: 100vh;
}

.crop-container {
  width: 80px;
  height: 69vh;
}

.containe_image {
  display: flex;
  width: 500px;
  height: 70vh;
}

/* .containe_croper {
    min-height: 338px;
    position: relative;
    background: #fff;
} */

.button_show {
  z-index: 999;
  position: absolute;
  bottom: 0;
}

.hidden {
  display: none;
}

.inage_div {
  height: 40px;
  background: #DADADA;
  border: 1px solid #DADADA;
  width: 200px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Profile_image {
  border-radius: 50%;
}

/*------------Karan Group-------------*/

span.titleGroup {
  color: #ffff;
  font-weight: 700;
  font-size: 21px;
  margin-left: 40px;
}

span.submenuList {
  color: #000000;
}

.addFleetModal {
  width: 425px;
}

.footerOfModal {
  display: flex;
  justify-content: start;
  margin-left: 17px;
}


.statusofOrder {
  /* width: 82px; */
  height: 24px;
  border: 1px solid;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
 background:#00515c;
}

.statusofOrder span{
  padding: 5px;
  /* padding-bottom: 2px; */
  color: #fff;
} 

.requiermentBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

button.Accept {
  border: 1px solid #9B9B9B;
  background: #5B5B5B;
  color: #ffff;
  padding: 10px;
  border-radius: 5px;
  font-size: 12px;
}

.date-input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 10px;
}

.dropdownoforder {
  font-size: 14px;
}

.anvLarge_modal {
  width: 100% !important;
  max-width: calc(96% - 5px) !important;
  /* margin: 0 !important;
  height: 100vh; */
}

.anvLarge_modal .modal-content {
  border-radius: 0;
  border-color: #B3B3B3;
  height: 100%;
}

.anvLarge_modal .modal-content .modal-body {
  padding: 0;
}

.mainTable_flex {
  display: flex;
  flex-wrap: wrap;
}

.leftTable_bx {
  width: 332px;
  background: #fff;
  border-bottom: 1px solid #B3B3B3;
  margin-bottom:15px
}

.rightTable_bx .tabBtn_bx {
  min-height: 69px;
  padding-left: 30px;
  border: 1px solid #B3B3B3;
  border-top: 0;
  align-items: center;
  justify-content: space-between;
}

.rightTable_bx .anvTask_prog {
  margin: 0;
  padding-top: 1em;
  padding-left: 1em;
  border-left: 1px solid #B3B3B3;
}

.anvMod_tableContainer {
  height: 370px;
  overflow: auto;
  border: 1px solid #9B9B9B;
  background-color: #fff;
  border-bottom: 0;
}

table.anvMod_table {
  width: 100%;
}

table.anvMod_table thead tr {
  background: #F0F0F0;
  position: sticky;
  top: 0;
}

table.anvMod_table thead tr th {
  font-size: 14px;
  font-weight: 400;
  line-height: 100%;
  padding: 5px 10px;
  color: #000;
  height: 39px;
}

table.anvMod_table tbody tr td {
  font-size: 14px;
  font-weight: 400;
  line-height: 28px;
  padding: 10px 10px 8px;
  border: 1px solid #9B9B9B;
  border-right: 0;
  border-left: 0;
  color: #000;
}

button.statusBtn {
  background: #fff;
  border: 1px solid #9B9B9B;
  border-radius: 20px;
  font-size: 12px;
  max-width: 82px;
  width: 100%;
}

.modactionBtn {
  border-radius: 5px;
  border: 1px solid var(--defaultBtn-border);
  background: var(--defaultBtn-bg);
  color: #fff;
  font-size: 12px;
  max-width: 102px;
  width: 100%;
}

table.anvMod_table thead tr th:nth-child(6),
table.anvMod_table thead tr th:nth-child(7) {
  text-align: center;
}

.tickSvg {
  display: flex;
  gap: 4px;
}

.rightTable_bx {
  width: calc(100% - 332px);
  border-bottom: 1px solid #B3B3B3;
  margin-bottom:15px
}

.allocateHead {
  min-height: 69px;
  padding-left: 30px;
  color: #000;
  font-size: 18px;
  font-weight: 400;
  line-height: 36px;
  display: flex;
  align-items: center;
  border: 1px solid #B3B3B3;
  border-top: 0;
  border-right: 0;
}

.leftTable_bx table.anvMod_table tbody tr td {
  border: 0;
}

.leftTable_bx .anvMod_tableContainer {
  border-right: 0;

}

select#cars {
  position: absolute;
  position: absolute;
  top: 36px;
  height: 28px;
  right: 6px;
  border-radius: 4px;
}

.dropdaonAndselect {
  position: relative;
}

.anvModalFooter {
  padding: 22px 16px 22px 34px;
  background-color: #E0E0E0;
  border-radius: 0;
  justify-content: space-between;
}

.anvFirst_sublistBx {
  margin: 0;
  max-width: 210px;
  width: 100%;
}

.anvFirst_sublist {
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  color: #000;
  line-height: 30.69px;
}

button.sendNominate {
  border-radius: 5px;
  border: 1px solid var(--defaultBtn-border);
  background: #21888b;
  color: #FFF;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  padding: 13px 18px;
  
 
}

button.sendNominate:disabled {
  border-radius: 5px;
  border: 1px solid var(--defaultBtn-border);
  background: var(--defaultBtn-bg);
  color: #FFF;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  padding: 13px 18px;
}

.anvTick_tabFlex {
  display: flex;
  align-items: center;
  gap: 7px;
}

.anvTick_tabFlex p {
  margin: 0;
  font-size: 14px;
  color: #000;
  line-height: 28px;
}

.avnTickFull_wid {
  display: flex;
  gap: 28px;
}

.otrTickBx {
  margin: 0;
}

.otrSend_bx {
  margin: 0;
}
.customerStatus{
  display: flex;

}

.TabHeadofmodal {
  border-bottom: 1px solid #B3B3B3;
  padding: 0px 22px;
  margin-top: -10px;
  margin-left: -1em;
  /* margin-right: -1em; */
}

.anvMaintab_container {
  padding: 1em 18px 0;
}

.TabHeadofmodal button.btnOpt {
  padding: 10px 12px;
}

.TabHeadofmodal .tabBtn_bx {
  gap: 0;
}

.TabHeadofmodal button.btnOpt:before {
  content: "";
  width: 100%;
  position: absolute;
  background: transparent;
  height: 5px;
  left: 0;
  bottom: 0;
}

.TabHeadofmodal button.active.btnOpt:before {
  background: #F3C82F;
}

.checkboxtext{
  display: flex;
  align-items: center;
  /* justify-content: center; */
  gap: 10px;
}

.checkboxTextbox{
  font-size: 14px;
  margin-bottom: 10px;
}

.anvOt_outerFull .customerAddress{
  font-size: 14px;
  margin-top: 20px;
  font-weight: 700;

}

.orderModal_Input1 {
  height: 37px;
  margin-top: 0px;
  /* border: 1px solid #9B9B9B; */
}

.orderModal_Input {
  height: 39px;
  margin-top: 0px;
  /* border: 1px solid #9B9B9B; */
  border-radius: 4px;
}

.addpermitDetails{
  display: flex;
  /* justify-content: center; */
  align-items: center;
  gap: 10px;

}

.modal_td{
  border: 1px solid #ddd;
  text-align: center;
  border-radius: 4px;
}

.addCrossAction {
    background: #B1B1B1;
    border: 1px solid #ddd;
    padding: 4px 22px;
    border-radius: 5px;
    margin-left: 10px;
}

.crossBorderTrailer{
  flex-direction: column;
  gap: 3em;
 
}

.ModalForFleettype {
  width: 100%;
  /* padding: 0 0 1rem 1rem; */
  flex-direction: column;
  display: flex;
  gap: 1rem
}

fieldset.RadioButton {
  display: flex;
  align-items: baseline;
  justify-content: center;
  gap: 1rem;
  flex-direction: column;
  margin-left: 5rem;
}

fieldset.loctionRadio {
  display: flex;
  align-items: center;
  margin-left: -19px;
  gap: 13px;
}
.csvModalBody{
  background: bisque;
}


.form-check-input:checked{
  background-color: #21888b;
}

.dashbordCountBox {
  display: flex;
  gap: 2rem;
}


.dashbordCountBoxFirst {
  display: flex;
  gap: 2rem;
  width: 40%;
  flex-direction: column;
}

.loaderGif {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50vh;
  
 
}

.loaderGifleft {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50vh;
  margin-right: 80rem;
 
}

.loaderGif img {
  filter: hue-rotate(-35deg);
}

.alloteFleetModal {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.ProductSpace {
  margin-block: 12px;
}

.btn-color{
  background: #F3C82F !important;
  color: #000000 !important;
  border: 1px solid #F3C82F !important ;
}

.NewInput-modal{
  width: 390px;
  height: 249px;
}

.NewInput-modal-fund{
  width: 726px;
  height: 249px;
}

.ModalFlexDiv2 {
  width: 340px;
  padding: 0 0 0rem 1rem;
  flex-direction: column;
  display: flex;
  gap: 1px;
}

.heightofcalender{
  min-height: 43px !important;
}

.button-width{
  width: 100% !important;
  background:#F3C82F !important;
  color:#000000 !important;
  border: 1px solid #F3C82F !important;
}

.footerOfModalNew {
  display: flex;
  justify-content: start;
  /* margin-left: 17px; */
}

.newModalInputFlex {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: -14px;
}

.UploadDocumentText {
  width: 173px;
  height: 37px;
  border: 1px solid #AAAAAA;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background: #ECECEC;
  color: #000000;
}

.UploadBox {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.UploadBox span{
  font-weight: 400;
  font-size: 16px;
}
.star{
  color: red;
}

.imagePreviewBox {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 10px;
  margin-bottom: 10px;
  flex-direction: column;
}

.imagePreviewBox .textdiv{
  border: 1px solid #DBDBDB ;
  width: 267px;
  height: 45px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.imagePre-div {
  width: 45px;
  /* height: 45px; */
  /* border: 1px solid #DBDBDB; */
  /* background: #D9D9D9; */
  border-radius: 4px;
  display: flex;
  height: 100%;
  flex-direction: column;
  gap: 25px;
  margin-top: 9px;
}

.imagePre-div img{
  width: 100%;
  height: 45px;
  object-fit: cover;
}

.selectRoute{
  margin-bottom: 30px;
}

.anvInr_imgBx {
  display: flex;
  height: 45px;
}

.imageFilename {
  display: flex;
  align-items: center;
  flex-direction: column;
  
}

.displayDelete {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  /* flex-direction: column; */
}

.divWrap{
  display: flex;
  gap:10px;
  /* flex-direction: column; */
}

.sigature_fund_div {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.UploadDriverText {
  width: 100%;
  border: 1px dotted #AAAAAA;
  /* display: flex; */
  font-size: 12px;
  text-align: center;
  padding: 12px;
  border-radius: 4px;
  margin-bottom: -18px;
  cursor: pointer;
}

.UploadDriverText span{
  color: red;
}

.img.diverImagePreview {
  height: 45px;
}

.tableMainHeadding {
  border-top: 1px solid #E4E4E4;
  border-bottom: 1px solid #E4E4E4;
  padding: 10px 7px 10px 28px;
}

.fundMainDiv {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

button.addfundMoreBtn {
  
  cursor: pointer;
  font-size: 14px;
  margin-left: 40px;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 4px 20px;
  background: #F3C82F;
  color: #000000;

}

.hedding_bold{
  font-weight:500;
  font-size: 15px;
  
}

.fundtext {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.fundTable td:first-child {
  padding-left: 28px;
}
.fundtext2 {
  display: flex;
  gap: 20px;
}

.fundTable th{
  font-size: 14px;
  font-weight: 400;
}

td.fundinput {
  width: 25%;
}

td.fundinput2 {
  width: 20%;
}

.expTable_main {
  margin-left: -1rem;
}

.fundTable th:first-child {
  padding-left: 28px;
}

.tablePadding {
  padding-left: 12px;
}

.route{
  display: flex;
  justify-content: space-between;
}

.routemargin{
  margin-right: -1em;
}

.routemodalNew {
  width: 432px !important;
  /* height: 607px !important; */
}

.anvMaintab_container_route {
  padding: 0.5em 15px 0;
  height: 100% ;
  /* overflow: auto; */
}
.routeModal_Input {
  height: 44px;
  margin-top: 0px;
  /* border: 1px solid #9B9B9B; */
}

.routemodalNew .modal-content {
  height: 100%;
}
.mySelect__value-container{
  height: 42px;
 }

 .addLoctionBtn{
  color: #0038FF;
 cursor: pointer;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 200%;
 }

 .dropdownMargin{
  margin-bottom: 10px;
 }

 .inputMargin {
  margin-bottom: -0.5em !important;
}

.expenseTabdiv{
  display: flex;
  gap: 10px;
}
.expenseTabdiv .dropdownMargin{
  width: 100%;

}

.marginExpoBottom {
  margin-bottom: 2rem;
}

.change-data {
    background: #FCFCFF;
    border: 1px solid #E8E7E7;
    border-radius: 4px 4px 0px 0px;
    font-size: 14px;
    width: 100%;
    font-weight: 500;
    display: flex;
    justify-content: flex-start;
    text-align: left !important;
    color: #0049D7;
    padding-left: 1.5em;
    height: 34px;
    position: relative;
    text-transform: capitalize;
    z-index: 99;
    margin-top:5px

}

/* .change-data {
  background-color: #fff;
  border: 1px double #eee;
  color: rgb(41, 146, 208);
  padding: 20px 10px 25px 10px;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 10px;
  cursor: pointer;
  box-shadow: 4px 4px 3px #999999;
} */

.button_group {
  display: flex;
  gap: 10px;
}

tbody.table_body_style {
  font-size: 14px;
}

.locationDetails {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

fieldset.orderRadio {
  display: flex;
  align-items: center;
  margin-left: -4rem;
  gap: 45px;
}

input.radioInput.form-check-input {
  width: 25px;
  height: 25px;
  margin-right: 10px;
}

.addMoreBtn{
  border: none;
  background: none;
  color:#0049D7;
  padding-top: 8px;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
}

img.diverImagePreview {
  width: 100%;
  height: 50px;
  object-fit: cover;
}

.driverName{
  cursor: pointer;
  cursor: pointer;
  text-decoration: underline;
  color: #0d5bfd;
}

.driverDetailsPage {
  width: 60%;
  margin: 20px;
 
}
.driverDetailsPage span{
  margin-left: 1rem;
}

.overflowDriver {
    min-height: calc(100vh - 207px);
    height: calc(100vh - 207px);
    overflow: auto;
}

.driverFlex{
  display: flex;
  flex-direction: column;
}

.spanName{
  font-size: 14px;
  font-weight: 500;
}

.spanFirst{
  margin-left: 10px;
  color: #BE0000
  ;
}
.spanSecond{
  margin-left: 10px;
  color: #42CD00
  ;
}

.spanThird{
  margin-left: 10px;
  color:#0038FF
  ;
}

.diplayDriver {
  display: flex;
  gap: 15px;
}

.deleteBoxDriver{
  margin-top: 2rem;
}
.addMoreButtonDriver{
  background:none;
  border:none;
  color:#0049D7;
  font-size: 14px;
  
}

input.orderModal_Input.widthDriver.form-control{
  width:52px;
}

.widthDriverDrop{
width:109px
}

.alloteFleetTable tr td :nth-child(5){
  width: 20px 
}
.anvSpecificDiv .anvTxt_fielW {
  position: relative;
}

.fltBtn {
  position: absolute;
  display: none;
}

.fltBtn2 {
  position: absolute;
  right: 10px; /* Adjust as needed */
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 99; /* Ensures it stays above the input field */
  background: transparent; /* Optional for button transparency */
  border: none;
  outline: none;
}
.fltBtn2:focus {
  outline: none; /* Prevent focus outline on the eye icon */
}

.anvSpecificDiv .fltBtn2 {
  display: block;
  z-index: 9;
}

.anvSpecificDiv .fltBtn {
  display: block;
  right: 10px;
  top: 5px;
  border: 1px solid #ddd;
  padding: 1px 16px;
  z-index: 9;
}

button.closeIconeFont svg {
  font-size: 1.5rem;
}

.anvInr_imgBx svg{
  font-size: 2.7rem;
}

.customerPreview span{
font-size: 14px;
padding-bottom: 10px;
}

.customeClassForTrip{
  width: calc(100% - 35%) !important;
}

.customeClassForRole{
  width: calc(100% - 40%) !important;
}

.customeClassForfuel{
  width: calc(100% - 75%) !important;
}

td.deleteIconeWidth {
  width: 52px;
  padding: 13px;
  text-align: center;
}

/* .mySelect{
  height: 40px;
} */

/* .anvDrop_down__menu {
position: fixed !important;
z-index: 99;
} */


.trips_Details {
  border: 1px solid #ddd;
  height: 35px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 15px;
  margin-bottom: -6px;
}

.trips_Details span{
  font-size: 14px;
}

.tripMainDiv {
  display: flex;
  margin: 2.5rem 2rem;
  gap: 1rem;
}

.fistDivOFTrip {
  width: 58rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.tableForTripdetails table {
  border-bottom: 1px solid #9B9B9B !important;
  border-radius: 4px;
}

.tableForRoledetails table {
  border-bottom: 1px solid #9B9B9B !important;
  border-radius: 4px;
}
.tableForTripdetails{
  border: 1px solid #9B9B9B !important;
}

.tableForRoledetails{
  /* border-top: 1px solid #ddd !important; */
  margin-left: -15px;

}


.TripFirstDiv {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.tripButton {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.tripButton button {
  border: 1px solid #9B9B9B;
  background: #E8E8E8;
  border-radius: 4px;
  font-size: 12px;
  padding: 5px 15px;
  font-weight: 400;
}


.tripButton span{
  font-size: 14px;
  font-weight: 400;
}

.tableForTripdetails table th{
  font-size: 14px;
  font-weight: 400;
  
}

.tableForRoledetails table th{
  font-size: 14px;
  font-weight: 400;
  
}

.tableForTripdetails table{
  margin-bottom: 0px;
  
}

.tableForRoledetails tr td{
  font-size: 14px;
}
.tableForTripdetails tr td{
  font-size: 14px;
}

.tableForRoledetails tr td:first-child{
  padding-left: 2rem;
}
.flex_td{
  display:flex;
  gap: 5px;
  margin-bottom: -10px;
}

.tableForTripdetails .modal_td{
  border: none;
  border-bottom: 1px solid #9B9B9B;
  text-align:start;
  font-size: 12px;
  
}

.secondDivofTrip {
  width: 15rem;
  margin-top: 9px;
}

.userRoleInput{
  width: 13rem !important;
}

.trailerdetails_box{
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.trailerdetails_box .first{
  margin-left: 1px;
  font-size: 14px;
}

.input_bottom_margin{
  margin-bottom: 3rem;
}


.py-2 {
  padding-top: 0.5rem!important;
  padding-bottom: 0.5rem!important;
  padding-left: 20px;
}

.tableForRoledetails label{
  font-size: 14px;
}

.sortFortable{
  position: relative;
}

svg.downsort {
  position: absolute;
  top: 5px;
  left: 3px;
  
}

svg.upsort {
  position: absolute;
  left: 3px;
  
}

svg.downsort.fontsizeOFArrow {
  font-size: 18px;
  left: 1px;
  top: 4px;
 
}

.pointerShort{
  cursor: pointer;

}

svg.upsort.fontsizeUPArrow {
  font-size: 18px;
  top: -4px;
  left: 1px;
 
}

.signature_pad_style {
  width: 99%;
  height: 200;

  margin: 2px;
}

button.saveButton {
  border: 1px solid #21888b;
  background: #21888b;
  padding: 2px 12px;
  border-radius: 3px;
  color: #ffff;
  font-weight: 400;
}

button.cleartButton {
  border: none;
  background: none;
  color: #21888b;
  font-weight: 500;
}

.driverSignature {
  position: relative;
}

.pencil_for {
  position: absolute;
  top: 13px;
  right: 10px;
  cursor: pointer;
}

.image-container {
  width: 325px;
  height: 50px;
  border: 1px solid #ccc;
  text-align:center;
}
.image-container img {
  max-width: 100%;
  height: 100%;
               

}

.marginB{
  margin-bottom: 8px;
}

.margin_none{
  margin-bottom: -20px !important;
}

.input_radius{
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

.anvAverage_height {
  height: calc(100vh - 153px);
  overflow: auto;
}

svg.walletIng {
  font-size: 26px;
  cursor: pointer;
}


.table_hieght{
  /* height: 72vh !important; */
}

th.sorting_asc{
  padding-left: 1rem;
}

table.table.tabNew tr td {
  padding-left: 1rem;
}

.inr_flex_mini1 {
  flex: 0 0 211px;
  margin-bottom: 10px;
}

.trip_Date_filter {
  display: flex;
  gap: 10px;
 
  align-items: center;
}

.btn_trip_donload {
  margin-right: 10px;
}

.trip_display{
  display: flex;
  gap: 15px;
  align-items: center;
}
.other_menu{
  
  box-shadow: none !important;
  
}

.css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper{
  box-shadow: 0px 0px 6px 1px rgba(0,0,0,0.1), 0px 0px 0px 1px rgba(0,0,0,0.1), 0px 0px 0px 1px rgba(0,0,0,0.1) !important
}

.css-6hp17o-MuiList-root-MuiMenu-list {
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  padding-top: 4px;
  /* padding-bottom: 8px; */
  outline: 0;
}

.checkBox_Cnacle{
  display: flex;
  gap:5px;
}

.react-datetime-picker {
  height: 40px;
  width: 100%;
  display: inline-flex;
  position: relative;
  
}

.react-datetime-picker__wrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  border-radius: 4px;
  border: thin solid #ced4da;
}

.react-datetime-picker__calendar {
  width: 325px;
  max-width: 100vw;
}

.react-calendar {
  width: 350px;
  max-width: 100%;
  background: white;
  border: 1px solid #a0a096;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1em;
}

.datepicker:focus {
  /* Add your styles for focused state here */
  outline: 2px solid blue;
}

.fleetGroup_input {
  display: flex;
  gap: 1rem;
}

.fleet_moiter {
  display: flex;
  align-items: baseline;
  gap: 2rem;
}

.fleet_moniter_dash button {
 
  border: 1px solid #ddd;
  padding: 10px 20px;
  border-radius: 25px;
}

.dashboard_iner_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.fleet_moniter_container {
  display: flex;
  flex-direction: column;
  width: 40%;
}

.dashboard_iner_container table{
  margin-left: -2rem;
  margin-right: -2rem;
}

td.span_of_table{
  text-align: center !important;
}

td.span_of_table2{
  text-align:start !important;
 padding: 0rem 0rem 0rem 42rem;
}

.details_div {
  /* border: 1px solid #ddd; */
  height: 70vh;
  width: 40%;
  overflow: auto;
 
}

.details_card {
  border: 1px solid #ddd;
  border-radius: 17px;
  padding: 1rem;
  margin-right: 3rem;
  cursor: pointer;
  margin-bottom: 1rem;
}
.details_card h3{
  font-size: 14px;
  
}

.details_card p{
  font-size: 14px;
width: 80%;
}

.details_card span{
  font-size: 14px;
  color: #FF2B2B;
}

.details_card h3 {
  font-size: 20px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 8px;
}

.driver_trip {
  display: flex;
  gap: 1rem;
}

.driver_trip span{
  color:#000
}

.map_div {
  width: 60%;
  margin-top: -4.5rem;
  height: 75vh;
  border: 1px solid #9B9B9B;
  border-radius: 12px;
}

.switch_div {
  display: flex;
}

.fleetNameDiv span{
  font-size: 10px;
  text-decoration: underline;
    color: #0d5bfd;
}
.fleetNameDiv {
  display: flex;
  flex-direction: column;
  gap: 0px;
}

.details_card_map {
  border: 1px solid #ddd;
  border-radius: 17px;
  padding: 1rem;
  /* margin-right: 3rem; */
}

.details_card_map h3{
  font-size: 14px;
  
}

.details_card_map p{
  font-size: 14px;
width: 80%;
}

.details_card_map span{
  font-size: 14px;

}

.details_card_map h3 {
  font-size: 20px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 8px;
}

.details_map {
  display: flex;
  gap: 1rem;
}


.trip_map {
  width: 55%;
  height: 40vh;
}

.tripDriver_colr{
  color: #FF2B2B !important;
  font-size: 14px;
}

.fuelActionbtn {
  background: none;
  padding: 4px 2rem;
  border: 1px solid #9B9B9B;
  border-radius: 4px;
}

.button_div {
  display: flex;
  gap: 10px;
}

.otp_div {
  display: flex;
  flex-direction: column;
}

.ammount_status {
  display: flex;
  gap: 10px;
}
/* .otp_div span{
  font-size: 10px;
} */

.circle {
  width: 10px;
  height: 10px;
  background-color: #2e9904;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 10px;
  font-weight: bold;
}

.circle_red {
  width: 10px;
  height: 10px;
  background-color: #990424;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 10px;
  font-weight: bold;
}

.online-truck {
  display: flex;
  align-items: center;
  gap: 5px;
}

.customeTable{
    min-height: calc(100vh - 245px);
    height: calc(100vh - 245px);
    overflow: auto;
    margin-bottom: 0px;
}

.Siganture_div {
  border: 1px solid #DBDBDB;
  width: 326px;
  height: 84px;
  display: flex;
  margin-right: 10px;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}
.Siganture_div img{
  width: 210px;
  height: 65px;
}
.signatureCheck{
  display: flex;
  gap: 10px;
 
}
.signatureCheck span{
  font-size: 12px;
}

.routeSelectDiv {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.otp_div_recev {
  display: flex;
  flex-direction: column;
  align-items: end;
  /* margin-top: -20px; */
  font-size: 14px;
}

.tripButton.margin-top {
  margin-top: -19px;
}



@mixin tablet-and-up {
  @media screen and (min-width: 769px) { @content; }
}
@mixin mobile-and-up {
  @media screen and (min-width: 601px) { @content; }
}
@mixin tablet-and-down  {
  @media screen and (max-width: 768px) { @content; }
}
@mixin mobile-only {
  @media screen and (max-width: 600px) { @content; }
}


ul, li{
list-style: none;
padding: 0;
}

/* .container{
display: flex;
justify-content: center;
align-items: center;
padding: 0 1rem;
background: linear-gradient(45deg, #209cff, #68e0cf);
padding: 3rem 0;
} */
/* .wrapper{
background: #eaf6ff;
padding: 2rem;
border-radius: 15px;
} */
h1{
font-size: 1.1rem;
font-family: sans-serif;
}
.sessions{
margin-top: 2rem;
border-radius: 12px;
position: relative;
}
.sessions li{
padding-bottom: 1.5rem;
border-left: 1px solid #abaaed;
position: relative;
padding-left: 20px;
/* margin-left: 10px; */
&:last-child{
  border: 0px;
  padding-bottom: 0;
}
&:before {
  content: '';
  width: 12px;
  height: 12px;
  background: #000;
  border: 1px solid #000;
 
  border-radius: 50%;
  position: absolute;
  left: -7px;
  top: 4px;
}
}
.time{
color: #2a2839;
font-family: 'Poppins', sans-serif;
font-weight: 500;
@include mobile-and-up{
  font-size: .9rem;
}
@include mobile-only{
  margin-bottom: .3rem;
  font-size: 0.85rem;
}

}
p{
color: #4f4f4f;
    font-family: sans-serif;
line-height: 1.5;
margin-top:0.4rem;
@include mobile-only{
  font-size: .9rem;
}
}

.timeline_document {
  width: 179px;
  height: 67px;
  border: 1px solid #D9D9D9;
  border-radius: 7px;
  display: flex;
    align-items: center;
    background-color: #D9D9D9;
    padding: 0px 0px 0px 10px;
}

.doc_img {
  width: 43px;
  height: 43px;
  background: #ffff;
}

.doc_info {
  display: flex;
  flex-direction: column;
}

span.doc_name {
  font-size: 12px;
  font-weight: 400;
}

span.doc_time {
  font-size: 9px;
  font-weight: 400;
}

span.upload_by {
  font-size: 10px;
  font-weight: 400;
}

button.addMore_button {
  background: none;
  border: none;
  font-size: 14px;
  color: #032cc1f2;
}
.doc_main {
  display: flex;
  gap: 1rem;
}

.main_container_table {
  margin-left: -2rem;
  margin-right: -2rem;
  overflow: auto;
  height: calc(100vh - 325px);
}

.main_container_table table{
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.main_container_table .table_head_style tr {
  position: sticky;
  top: 0;
}

table.tabNew {
  min-width: 100%;
  width: max-content;
}

.tabNew tr th {
  margin-right: 10px;
}

.Tab_dropList {
  display: flex;
  align-items: center;
  gap: 10px;
}

.upload_button_ui {
  display: flex;
  /* align-items: center;
  justify-content: center; */
  gap: 10px;
}

.Green{
  background: green;

  
}
.color_div{
  width: 15px;
  height: 15px;
}
.Red{
  background: red;
  
}

.Yellow{
  background: yellow;
 
}

.Blue{
  background: blue;
 
}

.serial_div {
  display: flex;
  align-items: baseline;
  gap: 5px;
}
.banner_div{
 
  width: 100%; /* Makes the image take full width of the sidebar */
  height:100vh; /* Adjust the height based on your design */
  overflow: hidden; 
}
.banner_div img{
  width: 100%; /* Makes the image take full width */
  height: 100%; /* Makes the image take full height */
  object-fit: fill;  
}

.flex_assambly_drop {
  display: flex;
  gap: 10px;
}



