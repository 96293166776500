.container_table {
    display: flex;
    flex-direction: column;
    font-family: "Inter", sans-serif;
    justify-content: center;
    padding: 0rem 0rem;
  }
  
  table {
    border-spacing: 0;
    border-bottom: 1px solid #d1cccc;
  }
  
  tr:last-child td {
    border-bottom: 0;
  }
  
  th{
    background:#E6EEFC !important;
    border-bottom: 1px solid #d1cccc;
    border-right: 1px solid #d1cccc;
    font-size: 14px;
  }
  th,
  td {
    margin: 0;
    padding: 0.5rem 1rem 0.5rem 1rem;
    font-size: 14px;
  }
  
  th:first-child
  {
    border-right: 0;
    padding-left: 1rem;
  }

  td:first-child{
    border-right: 0;
    padding-left: 1rem;
  } 
  
  /* tr:nth-child(odd) {
    background: #f3f3f3;
    color: #000000;
  }
  
  tr:nth-child(even) {
    background: #ffffff;
    color: #000000;
  } */
  
  /* th.desc {
    border-bottom: 5px solid rgb(136, 136, 255);
  }
  
  th.asc {
    border-top: 5px solid orange;
  } */
  