.App {
  display: flex;
  width: 100%;
  align-items: stretch;
}

@import "https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700";

body {
  font-family: "Poppins", sans-serif;
  background: #ffffff !important;
}

a,
a:hover,
a:focus {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s;
}

/* ---------------------------------------------------
      SIDEBAR STYLE
  ----------------------------------------------------- */
.sidebar {
  min-width: 250px;
  max-width: 250px;
  width: 250px !important;
  background: #ad8c06;
  color: #fff;
  margin-left: -250px;
  transition: all 0.5s;
  height: 100vh;
  z-index: 999;
  position: fixed;
  
  /* Ensures the sidebar takes full height */
  display: flex;
  flex-direction: column;
}

.sidebar.is-open {
  margin-left: 0;
  transition: 0.5s;
  max-width: 250px;
  /* position: sticky;
    top: 0;
    z-index: 999; */
}

.sidebar-header {
  background: #ad8c06;
}

.sidebar-header h3 {
  color: #fff;
  padding: 1em;
}

.sidebar ul p {
  color: #fff;
  padding: 10px;
}

/* .sideBarLogo_div {
  margin-bottom: 1em;
  height: 58px;
  padding: 6px;
  display: flex;
  align-items: center;
} */

.sideBarLogo_div {
  margin-bottom: 0;
  height: 57px;
  /* padding: 2px 0px 2px 2em; */
  display: flex;
  border-right: 1px solid #ddd;
  justify-content: center;
  align-items: center;
  background-color: #ffff;
  font-size: 30px;
  color: #614e04;
}

.sideBarLogo_div img {
  /* width: 100%; */
  margin: 0;
  /* padding: 0 10px; */
}

.menu-open {
  background: #6d7fcc;
}

.anvSubmenu ul.list-unstyled {
  background: #ad8c06;
}
button.nav-link {
  width: 100%;
}
.anvSubmenu ul.list-unstyled li a.nav-link {
  padding-left: 3em;
}

.anvSubmenu ul.list-unstyled li a.nav-link:hover {
  background-color: #ad8c06;
}

/* .nav-item:hover {
  color: white;
  background: #44447c;
  font-weight: 400;
} */

ul.nav.nav-tabs a.nav-link {
  color: #000000 !important;
  font-size: 16px;
  border: 0;
  padding: 15px 30px;
}

ul.nav.nav-tabs a.nav-link.active {
  background-color: transparent;
  font-weight: 700;
}

.nav-item>.nav-link.active {
  color: white;
  background: #665307;
  border-left: 3px solid #fff;
}

.items-menu {
  color: #fff;
  background: #6d7fcc;
}

li a.dropdown-toggle::after {
  display: inline-flex;
  position: relative;
  left: 60%;
  top: 10%;
}

.sidebar-header>span {
  position: relative;
  float: right;
  margin: 0.5em;
  font-size: 2rem;
  cursor: pointer;
  display: none;
}

.side-menu {

  height: calc(100vh - 432px);
  overflow-y: scroll;
  padding: 0 0.5em 0 1.4em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.side-menu::-webkit-scrollbar {
  width: 10px;
}

.side-menu::-webkit-scrollbar-thumb {
  background: 212121;
  border-radius: 3px;
}

.side-menu::-webkit-scrollbar-thumb:hover {
  background: #3a4c99;
}

ul.list-unstyled .nav-link {
  display: flex;
  grid-column-gap: 11px;
  align-items: center;
  padding-left: 0.5em;
  padding-right: 0.5em;
}

.nav-link {
  color: white !important;
}


ul.list-unstyled a.active.nav-link {
  border-left: 3px solid #fff;
}

.sidebar_logo {
  font-size: 20px;
}


/* ---------------------------------------------------
      CONTENT STYLE
  ----------------------------------------------------- */
.content {
  padding: 0 !important;
  margin-left: auto;
  height: 100vh;
  transition: 0.5s;
  width: 100%;
}

.content.is-open {
  width: calc(100% - 250px);
  margin-left: auto !important;
  transition: 0.5s;
}

@media only screen and (max-width: 500px) {
  body {
    overflow: hidden;
  }

  .content.is-open {
    margin-left: 100%;
    width: 100% !important;
  }

  .content.is-open .bottomBtn_div {
    width: 100% !important;
  }

  .sidebar {
    z-index: 999;
  }

  .sidebar.is-open {
    min-width: 50%;
    max-width: 50%;
    margin-left: 0;
    transition: all 0.5s, height 0s;

  }

  .sidebar.is-open>.sidebar-header span {
    display: unset;
  }

  li a.dropdown-toggle::after {
    display: inline-block;
    position: relative;
    left: 68%;
  }
}