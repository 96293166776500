.filter_boxGrid {
    display: flex;
    flex-wrap: wrap;
    background: #e0e6e6;
    border: 1px solid #c8ced3;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    gap: 18px 24px;
}

.filterFx_basis {
    flex: 0 0 calc(33.33% - 16px);
}

.cstFrm_inputs {
    width: 100%;
    padding: 6px;
    border: 1px solid #ced4da;
    background: #fff;
    border-radius: 3px;
    min-height: 36px;
}

.cstFrm_inputs:focus {
    border-color: var(--body-color-secondary);
}

.cstFrm_inputs:focus-visible {
    outline: -webkit-focus-ring-color auto 0px;
}

.cstFrm_inputs {
    position: relative;
}

.cstFrm_inputs[type="date"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
}

.cstFrm_inputs[type="datetime-local"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
}

.mainDate_picBx {
    position: relative;
    font-size: 14px;
}

.dateSvg {
    position: absolute;
    top: 5px;
    right: 9px;
}

.getHistoryBtn {
    width: 100%;
    background-color: var(--body-color-secondary);
    color: var(--body-color-primary);
    border: 0;
    padding: 8px;
    font-weight: 600;
}

.getHistoryBtn:hover {
    background-color: #fcd139 !important;
    color: var(--body-color-primary);
}


input[type="date"]::before {
    content: attr(placeholder);
    position: absolute;
    color: #999999;
  }
  
  input[type="date"] {
    color: #ffffff;
  }
  
  input[type="date"]:focus,
  input[type="date"]:valid {
    color: #666666;
  }
  
  input[type="date"]:focus::before,
  input[type="date"]:valid::before {
    content: "";
  }